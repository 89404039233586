export const   productSwatchInfo = {
  priceInfo:  'Precio',
  month:      'mes',
  day:        'día',
  color:      'Escoge tu tono:',
  moreColors: '+ tonos',
  noColors:   'No hay colores disponibles'
}

export default productSwatchInfo;
